import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import produce from "immer";
import {
  selectDayOrders,
  getOrderDetails,
  selectNewOrders,
  // selectLocations,
  // selectStatistics,
  selectLoaded,
  getMonitorOrders,
  acceptMonitorOrder,
  updateMonitorOrder,
  assignMonitorDriver,
  finishMonitorOrder,
  cancelMonitorOrder,
  // deliveryLocation,
  getBranchDrivers,
  // transferMonitorOrder,
  // updateNotesClient,
  setLoaded,
} from "../../redux/slices/monitor";
import { selectUser } from "../../redux/slices/user";
import MonitorUI from "./components/MonitorUI";

const Monitor = () => {
  const [modalOrder, handleModalOrder] = useState(false);
  // const [modalCommentaries, handleModalCommentaries] = useState(false);
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [modalDriverConfirm, handleModalDriverConfirm] = useState(false);
  const [orderDetail, handleOrderDetail] = useState({});
  const newOrders = useSelector(selectNewOrders);
  const dayOrders = useSelector(selectDayOrders);
  // const branches = useSelector(selectLocations);
  // const statistics = useSelector(selectStatistics);
  const loaded = useSelector(selectLoaded);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const [response] = await Promise.all([
      dispatch(getMonitorOrders(user.branch, user.admin)),
    ]);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    }
  }, [dispatch, user]);

  useEffect(() => {
    const getEverything = async () => {
      await initialFetch();
      dispatch(setLoaded(true));
    };
    getEverything();
  }, [dispatch, initialFetch]);

  useEffect(() => {
    const countup = setInterval(initialFetch, 30000);

    return () => {
      clearInterval(countup);
    };
    // initialFetch();
  }, [initialFetch]);

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      handleModalOrder(true);
    }
  };

  const closeDetails = () => {
    handleModalOrder(false);
    handleOrderDetail({});
  };

  const updateOrderStatus = (status) => {
    const newOrder = produce(orderDetail, (draftState) => {
      draftState.status = status;
    });
    handleOrderDetail(newOrder);
  };

  const openDriverConfirm = (order) => {
    handleModalDriverConfirm(true);
  };

  const closeDriverConfirm = () => {
    handleModalDriverConfirm(false);
    handleModalOrder(true);
  };

  /* const openModalCommetaries = (type) => {
    handleModalCommentaries(type);
  };

  const closeModalCommetaries = () => {
    handleModalCommentaries(false);
    handleModalOrder(true);
  }; */

  const acceptOrder = async () => {
    const response = await dispatch(
      acceptMonitorOrder(orderDetail.id, user.branch)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(7);
      message.success("Orden en preparación");
    }
  };

  /* const updateNotes = async (notes) => {
    const response = await dispatch(
      updateNotesClient(
        orderDetail.Customer.userId,
        orderDetail.Address.id,
        notes,
        orderDetail.id
      )
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Notas editadas");
    }
  }; */

  const updateOrder = async () => {
    const response = await dispatch(updateMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(11);
      message.success("Orden lista para recoger");
    }
  };

  const assignOrder = async (driver) => {
    const response = await dispatch(
      assignMonitorDriver(orderDetail.id, driver)
    );
    console.log('asignado y respuesta');
    console.log(response);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      //handleOrderDetail(response.order);
      //await initialFetch();
      updateOrderStatus(10);
      message.success("Orden asignada");
    }
  };
 
  /* const transferOrder = async (branch) => {
    const response = await dispatch(
      transferMonitorOrder(orderDetail.id, branch)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden trasnferida");
    }
  }; */

  const finishOrder = async () => {
    const response = await dispatch(finishMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(12);
      message.success("Orden finalizada");
      // closeModalCommetaries();
    }
  };

  const cancelOrder = async () => {
    const response = await dispatch(cancelMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(15);
      message.success("Orden cancelada");
      // closeModalCommetaries();
    }
  };

  const getDriversBranch = async (location) => {
    const response = await dispatch(getBranchDrivers(location));
    if (response.status !== "success") {
      message.error("Hubo un problema");
      return [];
    }
    return response.drivers;
  };
/*
  const handleDelivery = async (idBranch, index, val) => {
    const response = await dispatch(deliveryLocation(idBranch, index, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  }; 
*/

  const isAdmin = user.admin;
  const isDriver = user.driver;
  
  return (
    <MonitorUI
      modalOrder={modalOrder}
      openDetails={openDetails}
      loadingOrder={loadingOrder}
      newOrders={newOrders}
      dayOrders={dayOrders}
      // branches={branches}
      loaded={loaded}
      user={user}
      isAdmin={isAdmin}
      isDriver={isDriver}
      orderDetail={orderDetail}
      acceptOrder={acceptOrder}
      updateOrder={updateOrder}
      assignOrder={assignOrder}
      finishOrder={finishOrder}
      cancelOrder={cancelOrder}
      closeDetails={closeDetails}
      getDriversBranch={getDriversBranch}
      openDriverConfirm={openDriverConfirm}
      // openModalCommetaries={openModalCommetaries}
      // closeModalCommetaries={closeModalCommetaries}
      // modalCommentaries={modalCommentaries}
      // transferOrder={transferOrder}
      // updateNotes={updateNotes}
    />
  );
};

export default Monitor;
