import { useState, useEffect } from "react";
import { Col, Row, Form, Input, Upload, Button, message, Select } from "antd";
import { useDispatch } from "react-redux";
import {
  createSlide,
  updateSlide,
  newImage,
} from "../../../redux/slices/frontpage";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";

const { Option } = Select;

const Editor = ({ banner, closeEditor, handleBanner }) => {
  const [sending, handleSending] = useState(false);
  const [baseImg, handleBaseImg] = useState("");
  const [baseImg2, handleBaseImg2] = useState("");
  const [target, setTarget] = useState("take_out");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (banner) {
        const newValues = {
          url: banner.url || "",
          sort: banner.sort || 0,
          name: banner.name,
          target: banner.target,
        };
        handleBaseImg(banner.image || "");
        handleBaseImg2(banner.image_movil || "");
        setTarget(banner.target);
        form.setFieldsValue(newValues);
      } else {
        form.setFieldsValue({ target });
      }
    };
    initialFetch();
  }, [banner]);

  const uploadImage = async (valueImage, id, type) => {
    const isWeb = type === "web";
    const imagePreview = isWeb ? baseImg : baseImg2;
    const imageBanner = isWeb ? banner?.image : banner?.image_movil;
    if (imagePreview !== imageBanner || !banner) {
      const infoImage = new FormData();
      infoImage.append("id", id);
      infoImage.append("type", isWeb ? "image" : "image_movil");
      infoImage.append(
        "image",
        valueImage?.length ? valueImage[0]?.originFileObj : imagePreview
      );
      const response = await dispatch(newImage(infoImage));
      if (response.status === "success") {
        message.success("Cambios realizados con éxito");
        return response;
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
        return false;
      }
    } else if (target === "eat_in" || type === "mobile") {
      message.success("Cambios realizados con éxito");
      return false;
    }
  };

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (
        baseImg &&
        baseImg.length &&
        (target === "eat_in" || (baseImg2 && baseImg2.length))
      ) {
        const info = {
          name: values.name,
          target: values.target,
          sort: values.sort,
        };
        if (banner) {
          info.id = banner.id;
          info.status = banner.status;
        } else {
          info.status = 1;
        }
        if (target === "take_out") {
          info.url = values.url;
        }

        if (banner) {
          const response = await dispatch(updateSlide(info));
          if (response.status === "success") {
            await uploadImage(values.image, banner.id, "web");
            if (target === "take_out") {
              await uploadImage(values.image2, banner.id, "mobile");
            }
          } else {
            message.error("¡Hubo un problema al subir el banner! Inténtalo de nuevo");
          }
        } 
        else {
          const response = await dispatch(createSlide(info));
          if (response.status === "success") {
            let response_image = await uploadImage(
              values.image,
              response.banner.id,
              "web"
            );
            if (target === "take_out") {
              response_image = await uploadImage(
                values.image2,
                response.banner.id,
                "mobile"
              );
            }
            handleBanner(response_image.banner);
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Seleciona una imagen válida");
      }

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <span>{banner ? "Editar banner" : "Agregar banner"}</span>
            <Close action={closeEditor} />
          </div>
        </Col>
      </Row>
      <Form
        form={form}
        name="product-editor"
        layout="vertical"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className={styles.form}
        onFinish={save}
        initialValues={{ promo: false, url: "" }}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>PLATAFORMA</span>}
                  name="target"
                  className={styles.itemColumn}
                >
                  <Select
                    onChange={setTarget}
                    size="large"
                    className={styles.input}
                  >
                    <Option value="take_out">Take Out</Option>
                    <Option value="eat_in">Eat In</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10}>
                <div
                  className={styles.preview}
                  style={{ backgroundImage: `url(${baseImg})` }}
                />
              </Col>
              <Col span={14}>
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label={
                    <span className={styles.label}>IMAGEN DE BANNER WEB</span>
                  }
                  extra={
                    <span className={styles.center}>
                      (Formatos jpg/png de 1600x700px)
                    </span>
                  }
                >
                  <Upload
                    showUploadList={false}
                    customRequest={(e) => dummyRequest(e, handleBaseImg)}
                    beforeUpload={beforeUpload}
                    className={styles.upload}
                  >
                    <Button size="large" className={styles.uploadImage} block>
                      Seleccionar imagen
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            {target === "take_out" && (
              <Row gutter={20}>
                <Col span={10}>
                  <div
                    className={styles.preview}
                    style={{ backgroundImage: `url(${baseImg2})` }}
                  />
                </Col>
                <Col span={14}>
                  <Form.Item
                    name="image2"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    label={
                      <span className={styles.label}>
                        IMAGEN DE BANNER MÓVIL
                      </span>
                    }
                    extra={
                      <span className={styles.center}>
                        (Formatos jpg /png de 960x1500px)
                      </span>
                    }
                  >
                    <Upload
                      showUploadList={false}
                      customRequest={(e) => dummyRequest(e, handleBaseImg2)}
                      beforeUpload={beforeUpload}
                      className={styles.upload}
                    >
                      <Button size="large" className={styles.uploadImage} block>
                        Seleccionar imagen
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Form.Item
              label={<span className={styles.label}>NOMBRE</span>}
              name="name"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar nombre" }]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
            <Form.Item
              label={<span className={styles.label}>POSICIÓN</span>}
              name="sort"
              className={styles.itemColumn}
              rules={[{ required: true, message: "Ingresar posición" }]}
            >
              <Input size="large" className={styles.input} type="number" />
            </Form.Item>
            {target === "take_out" && (
              <Form.Item
                label={<span className={styles.label}>ENLACE</span>}
                name="url"
                className={styles.itemColumn}
              >
                <Input
                  size="large"
                  className={styles.input}
                  placeholder="Ejemplo: https://gobuff.com/enlace"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.submitWrap}>
              <Form.Item className={styles.itemSubmit}>
                <Button
                  loading={sending}
                  className={styles.submit}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  GUARDAR BANNER
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
