import { Table, Modal, Spin, Button, Switch } from "antd";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import Editor from "./Editor";
import styles from "../styles/FrontpageUI.module.css";

const FrontpageUI = (props) => {
  const {
    loading,
    editorVisible,
    editBanner,
    newBanner,
    closeEditor,
    banner,
    handleBanner,
    banners,
    handleActive,
    confirmDelete,
  } = props;

  const columns = [
    {
      title: "Banner",
      dataIndex: "image",
      width: 150,
      render: (val) => (
        <div
          className={styles.banner}
          style={{ backgroundImage: `url(${val})` }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Plataforma",
      dataIndex: "target",
      align: "center",
      filters: [
        {
          text: "Take Out",
          value: "take_out",
        },
        {
          text: "Eat In",
          value: "eat_in",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.target === value,
      render: (val) => (val === "take_out" ? "Take Out" : "Eat In"),
    },
    {
      title: "Activo",
      align: "center",
      dataIndex: "status",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status === value,
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleActive(record.id, val)} />
      ),
    },
    {
      title: "Editar",
      align: "center",
      dataIndex: "id",
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => editBanner(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  /* const Banner = ({ slide, index }) => (
    <div className={styles.banner}>
      <Row gutter={5}>
        <Col span={20}>
          <div
            className={cx(styles.bannerPhoto, {
              [styles.innactive]: !slide.active,
            })}
            style={{ backgroundImage: `url(${slide.image})` }}
          />
        </Col>
        <Col span={4}>
          <div className={styles.bannerActions}>
            <div
              className={styles.actionWrap}
              onClick={() => editBanner(slide)}
            >
              <Edit />
            </div>
            <div
              className={styles.actionWrap}
              onClick={() => handleActive(slide.id, index, slide.active)}
            >
              <Deactivate />
            </div>
            <div
              className={styles.actionWrap}
              onClick={() => confirmDelete(slide.id)}
            >
              <Trash />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ); */

  return (
    <div className={styles.wrapper}>
      <Header
        title="Portada"
        actions={
          <div>
            <Button
              onClick={newBanner}
              type="primary"
              size="large"
              className="main-button"
            >
              NUEVO BANNER
            </Button>
          </div>
        }
      />
      {!loading ? (
        <div>
          <Table columns={columns} dataSource={banners} rowKey="id" />
        </div>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        destroyOnClose
        visible={editorVisible}
        footer={false}
        closable={false}
        width={600}
        onCancel={closeEditor}
      >
        <Editor
          banner={banner}
          closeEditor={closeEditor}
          handleBanner={handleBanner}
        />
      </Modal>
    </div>
  );
};

export default FrontpageUI;
